@use '@/styles/utils/mixins.scss' as *;

.productCard {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  border-radius: 1.25rem;
  -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
  padding: 2.5rem 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: $white;
  transition: all 0.2s ease-in-out;

  &:hover {
    -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
    border-color: $primaryAlt;
    cursor: pointer;
  }

  &.selected {
    background-color: $primary;
    color: $white;

    .heading {
      color: $white;
    }

    .description {
      color: $white;
    }
  }

  .image {
    margin: 0 auto;
  }

  @include mob() {
    width: 100%;
  }

  .productCardBody {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin-top: 1rem;
  }

  .heading {
    text-align: center;
  }

  .description {
    color: $neutral08;
  }

  .descriptionAlignment {
    text-align: center;
  }
}
